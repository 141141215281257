import { articleLayoutConfig } from '@hubcms/brand';

import { Labels } from '@hubcms/ui-taxonomy';
import { StoryLabelsProps } from './types';

function StoryLabels({ label, showLabel, sublabel, showSublabel, isPremium, ...labelProps }: StoryLabelsProps) {
  return (
    <Labels
      label={showLabel ? label ?? '' : ''}
      sublabel={showSublabel ? sublabel ?? '' : ''}
      isPremium={isPremium ?? false}
      showPremiumIcon={articleLayoutConfig.heroblockPremiumIconLocation === 'label'}
      data-testid="article-label"
      {...labelProps}
    />
  );
}

export default StoryLabels;
