import { StoryElement } from './story-element';

export type AudioVisualData = {
  agnoPlayerBrand: string;
  agnoPlayerCdnId: string;
  agnoPlayerLicense: string;
  agnoPlayerTheme: 'slim' | 'advanced';
  autoplayOnView: boolean;
  caption?: string;
  credit?: string;
  hasAdvertisementsSkipped: boolean;
};

export type AudioVisualStoryElement = StoryElement<'audiovisual', AudioVisualData>;

export function isAudioVisualElement(storyElement?: StoryElement | null): storyElement is AudioVisualStoryElement {
  return !!storyElement && storyElement.type === 'audiovisual';
}

export function createAudioVisualData(partialAudioVisualData: Partial<AudioVisualData> = {}): AudioVisualData {
  return {
    agnoPlayerBrand: '',
    agnoPlayerCdnId: '',
    agnoPlayerLicense: '',
    agnoPlayerTheme: 'slim',
    autoplayOnView: false,
    caption: '',
    credit: '',
    hasAdvertisementsSkipped: false,
    ...partialAudioVisualData,
  };
}
