import {
  PageBreak,
  Paragraph,
  PullQuote,
  Heading,
  ReadMore,
  Map,
  Interview,
  Table,
  Embed,
  List,
  InfoBlock,
  AudioVisual,
  SportsResult,
  Rating,
} from '@hubcms/ui-story-elements';
import { LiveBlog } from '@hubcms/ui-live-blog';
import { Image, Gallery } from '@hubcms/ui-image';
import { HtmlEmbed } from '@hubcms/ui-embed';
import { StoryElementType } from '@hubcms/domain-story-elements';

import { ElementType } from 'react';

type ElementComponentMap = Partial<Record<StoryElementType, ElementType>>;

export const ELEMENT_COMPONENT_MAP: ElementComponentMap = {
  /* eslint-disable camelcase */
  gallery: Gallery,
  image: Image,
  paragraph: Paragraph,
  infoblock_paragraph: Paragraph,
  pull_quote: PullQuote,
  sub_head: Heading,
  infoblock_sub_head: Heading,
  relation: ReadMore,
  code_block: HtmlEmbed,
  map: Map,
  embed: Embed,
  mhbe_video: Embed,
  list_numbered: List,
  infoblock_list_numbered: List,
  list_bulleted: List,
  infoblock_list_bulleted: List,
  page_break: PageBreak,
  infoblock_image: Image,
  interview: Interview,
  infoblock_group: InfoBlock,
  infoblock_headline: Heading,
  audiovisual: AudioVisual,
  table: Table,
  live_blog: LiveBlog,
  summary_group: InfoBlock,
  sports_group: InfoBlock,
  sports_result: SportsResult,
  rating: Rating,
  /* eslint-enable camelcase */
};
