import { Paragraph } from '@mediahuis/chameleon-react';
import { ComponentPropsWithoutRef, useContext } from 'react';

import { canDisplayLocationInIntro } from '@hubcms/brand';

import { StoryHeaderContext } from '../../../../data-access';

import { StoryIntro } from '../../StoryIntro';

type StoryHeaderIntroProps = Omit<ComponentPropsWithoutRef<typeof Paragraph>, 'children'> & {
  hasDropcap?: boolean;
};

function StoryHeaderIntro({ className, hasDropcap, ...paragraphProps }: StoryHeaderIntroProps) {
  const { introData, locationData } = useContext(StoryHeaderContext);

  return (
    <StoryIntro
      hasDropcap={hasDropcap}
      hasLocation={canDisplayLocationInIntro}
      introData={introData}
      locationData={locationData}
    />
  );
}

export default StoryHeaderIntro;
